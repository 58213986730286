import { getCookie, setCookie } from '/src/utils/auth';

import { ApiLoginGetUser } from '/src/api/login';
import { ApiGetStillCount } from '/src/api/search';

const tokenKey = 'DG3JJD2S8D';
const vipKey = 'HGYWS12HF';
const accessKey = 'JHG23RD';

const user = {
    state: {
        token: getCookie(tokenKey),
        vipFlag: getCookie(vipKey) === 'true',
        userInfo: {
            // actId: null
            // companyName: null
            // createTime: "1649428842912"
            // creatorGuid: ""
            // enableWeixinSub: true
            // expirationDate: null
            // guid: "1512440303993319425"
            // isDeleted: false
            // keyword: null
            // modifierGuid: ""
            // modifyTime: "1649428842912"
            // nickName: "gWW61964893"
            // phone: null
            // registerSource: null
            // tag: null
            // token: "769969eef9ec42caa2c31253019bc043"
            // tokenExpiredTime: "1650033673663"
            // weiXinHeadImgUrl: null
            // weiXinNickName: ""
            // weiXinOpenId: "oGUrV5uo_1J3l7_uH358Ra9WtW_Y"
            // weiXinUnionId: "ofVmr5yUxr8UFpK1itsPHvAQvquQ"
        },
        accessQuery: getCookie(accessKey),
        // 存储静态数据，多个页面使用
        staticData: {
            follow: ['待跟进', '已跟进'],
            collection: ['收藏', '取消收藏'],
            export: null,
        },
        // 剩余预览下载次数
        stillCount: {
            downloadCount: 0,
            previewCount: 0,
            stillPreviewCount: 0,
            stilldownloadCount: 0,
        },
    },

    mutations: {
        SET_USERINFO: (state, value) => {
            state.userInfo = value;
        },
        SET_STILLCOUNT: (state, value) => {
            state.stillCount = value;
        },
        SET_TOKEN: (state, value) => {
            setCookie(tokenKey, value);
            state.token = value;
        },
        SET_VIP: (state, value) => {
            setCookie(vipKey, value);
            state.vipFlag = value;
        },
        SET_ACCESSQUERY: (state, value) => {
            setCookie(accessKey, value);
            state.accessQuery = value;
        },
        SET_STATICDATA: (state, value) => {
            // console.log(value);
            state.staticData[value.key] = value.data;
        },
    },

    actions: {
        // 获取用户信息
        GetUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                ApiLoginGetUser()
                    .then(res => {
                        // 模拟
                        // console.log(JSON.stringify(res.data));
                        // res.vipFlag = false;

                        // 头像
                        if (!res.data.weiXinHeadImgUrl) {
                            res.data.weiXinHeadImgUrl =
                                '/images/default-avatar.png';
                        }

                        // 时间字段
                        res.data.createTime = parseInt(res.data.createTime);
                        res.data.modifyTime = parseInt(res.data.modifyTime);
                        res.data.tokenExpiredTime = parseInt(
                            res.data.tokenExpiredTime
                        );

                        commit('SET_USERINFO', res.data);
                        commit('SET_TOKEN', res.data?.token || '');
                        commit('SET_VIP', res.vipFlag);

                        resolve(res.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        // 获取剩余查看和下载次数
        GetStillCount({ commit }) {
            return new Promise((resolve, reject) => {
                ApiGetStillCount()
                    .then(res => {
                        // 测试
                        // res.data = {
                        //     downloadCount: 10,
                        //     previewCount: 10,
                        //     stillPreviewCount: 0,
                        //     stilldownloadCount: 0,
                        // };

                        commit('SET_STILLCOUNT', res.data);

                        resolve(res.data);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },

        // 设置访问参数
        SetAccessQuery({ commit }, value) {
            return new Promise((resolve, reject) => {
                commit('SET_ACCESSQUERY', value);

                resolve();
            });
        },

        // 设置token
        SetToken({ commit }, value) {
            return new Promise((resolve, reject) => {
                commit('SET_TOKEN', value);

                resolve();
            });
        },

        // 设置用户VIP
        SetUserVip({ commit }, value) {
            return new Promise((resolve, reject) => {
                commit('SET_VIP', value);

                resolve();
            });
        },

        // 设置静态数据
        SetStaticData({ commit }, { key, data }) {
            // console.log(key, data);
            return new Promise((resolve, reject) => {
                commit('SET_STATICDATA', {
                    key,
                    data,
                });

                resolve();
            });
        },

        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_USERINFO', {});
                commit('SET_TOKEN', '');
                commit('SET_VIP', false);
                // window.location.href = '/logout';

                resolve();
            });
        },
    },
};

export default user;
