import { Message, MessageBox } from 'element-ui';

import store from '/src/store';

let unauthorizedTips = false;

const unauthorizedCallback = msg => {
    // 多个请求发生时，只提示一次
    if (unauthorizedTips) {
        return false;
    }

    unauthorizedTips = true;

    MessageBox({
        title: '系统提示',
        message: msg || '登录超时，请重新登录！',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '确定',
    })
        .then(() => {
            unauthorizedTips = false;

            // 未登录，清除本地数据
            store.dispatch('FedLogOut');

            // window.location.reload();
        })
        .catch(() => {
            unauthorizedTips = false;
        });
};

export function responseSuccess(response) {
    const res = response.data;
    const tmpMsg = res.message;

    // code	错误代码	integer(int32)	integer(int32)
    // data		boolean
    // message	错误提示信息	string
    // vipFlag	是否VIP	boolean
    // status	返回状态：1：成功 0：失败	integer(int32)	integer(int32)
    // 通用的返回status=0的时候代表失败，把message抛出来，如果判断code为7000，和7001的时候，应该是要跳登录页，清掉本地的token
    // tranceId

    if (res.code && (res.code === 7000 || res.code === 7001)) {
        unauthorizedCallback(tmpMsg);

        return Promise.reject(res);
    } else if (res.code === 7008) {
        // 自定义错误处理
        return Promise.reject(res);
    } else if (res.status === 0) {
        Message({
            type: 'error',
            showClose: true,
            duration: 4000,
            message: tmpMsg || '未知错误，错误代码' + res.code + '！',
        });

        return Promise.reject(res);
    }

    return Promise.resolve(res);
}

export function responseError(error) {
    // console.log(error); // for debug
    const response = error.response;
    // code=1表示成功，code=0表示失败
    const res = response ? response.data : { msg: '请求发生错误！' };

    // console.log(error);

    if (res.type === 'unauthorized') {
        unauthorizedCallback(res.msg);
    } else {
        Message({
            // iconClass: 'message-icon-error',
            type: 'error',
            showClose: true,
            duration: 4000,
            message: res.msg || res.message || res.error || '网络发生错误！',
        });
    }

    return Promise.reject(res);
}
