import request from '/src/utils/request';

// 生成二维码
export function ApiLoginCreateQr(query) {
    return request({
        url: '/login/createQr',
        method: 'post',
        data: query,
    });
}

// 轮询登录状态
export function ApiLoginGetQrCodeState(query) {
    return request({
        url: '/login/getQrCodeState',
        method: 'post',
        data: query,
    });
}

// 根据用户手机号查询用户绑定微信公众号情况
export function ApiLoginQueryBindingByOpenId(query) {
    return request({
        url: '/login/queryBindingByOpenId',
        method: 'post',
        data: query,
    });
}

// 获取用户信息
export function ApiLoginGetUser(query) {
    return request({
        url: '/login/getUser',
        method: 'get',
        params: query,
    });
}

// 修改用户基本信息
export function ApiLoginUpdateElement(query) {
    return request({
        url: '/login/updateElement',
        method: 'post',
        data: query,
    });
}

// 获取客户资源配置
export function ApiLoginGetUserKhzyConfigure(query) {
    return request({
        url: '/login/getUserKhzyConfigure',
        method: 'get',
        params: query,
    });
}

// 修改客户资源配置
export function ApiLoginUpdateUserKhzyConfigure(query) {
    return request({
        url: '/login/updateUserKhzyConfigure',
        method: 'post',
        data: query,
    });
}
