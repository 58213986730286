import { ApiCreateOrder } from '/src/api/order';

export default {
    computed: {
        userInfo() {
            return this.$store.getters.userInfo || {};
        },
        staticData() {
            return this.$store.getters.staticData;
        },
        vipFlag() {
            return this.$store.getters.vipFlag || false;
        },
    },
    methods: {
        // 登录成功
        handleLoginSuccess() {
            const { vipFlag } = this;

            if (!vipFlag) {
                // 开通VIP
                this.$refs.BuyVip.handleOpen();

                return false;
            }
        },

        // 开通VIP&导出 - 生成订单
        handlePayConfirm(data) {
            // 生成订单，跳转订单支付
            this.$refs.OrderPay.handleOpen(data);
        },

        // 支付成功
        handlePaySuccess() {
            // 重新获取用户信息
            this.$store.dispatch('GetUserInfo');
        },

        // 登录及VIP判断
        handleIsLoginAndVip(noVip) {
            const { userInfo, vipFlag } = this;

            if (!userInfo.weiXinUnionId) {
                // 未登录
                this.$refs.Login.handleOpen();

                return false;
            } else if (!noVip && !vipFlag) {
                // 开通VIP
                this.$refs.BuyVip.handleOpen();
                // this.$refs.OrderPay.handleOpen();

                return false;
            }

            return true;
        },

        handleToCreateOrder({ openType, reportId = '' }) {
            return new Promise((resolve, reject) => {
                if (!openType) {
                    reject();

                    return false;
                }
                ApiCreateOrder({
                    // 开通类型 会员传guid 一次性导出传4
                    openType,
                    // 报告ID
                    reportId,
                })
                    .then(res => {
                        resolve(res);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },

        // 单次购买
        handleToBuyOne(openType, reportId) {
            const tmpTitle = openType === 4 ? '下载' : '预览';

            this.$confirm(
                '<p>今日' +
                    tmpTitle +
                    '次数已用完，继续' +
                    tmpTitle +
                    '需单独支付5元/次</p><p style="color: #999;">支付完成后将为您添加 1 次今日可用的' +
                    tmpTitle +
                    '次数</p>',
                '提示',
                {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确认支付',
                    cancelButtonText: '稍后再说',
                }
            )
                .then(async () => {
                    const tmpOrder = await this.handleToCreateOrder({
                        reportId,
                        openType,
                    });

                    // 支付操作
                    this.handlePayConfirm(tmpOrder.data);
                })
                .catch(() => {});
        },
    },
};
