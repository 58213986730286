import request from '/src/utils/request';
import fileRequest from '/src/utils/fileRequest';

// 列表
export function ApiQueryCompanyList(query) {
    return request({
        url: '/search/queryReportList',
        method: 'post',
        data: query,
    });
}

// 根据报告Id查询报告详情
export function ApiGetReportDetail(query) {
    return request({
        url: '/search/getReportDetail',
        method: 'get',
        params: query,
    });
}

// 查询剩余可查看次数
export function ApiGetStillCount() {
    return request({
        url: '/search/getStillCount',
        method: 'get',
    });
}

// 查看报告文件
export function ApiGetReportFile(query) {
    return request({
        url: '/search/getReportFile',
        method: 'post',
        data: query,
    });
}

// 跟进收藏
export function ApiCompanyFollow(query) {
    return request({
        url: '/search/follow',
        method: 'post',
        data: query,
    });
}

// 获取热词
export function ApiGetHotWords(query) {
    return request({
        url: '/search/queryHotWords',
        method: 'get',
        params: query,
    });
}

// 获取统计数据和热词
export function ApiGetHotWordsWithAmount(query) {
    return request({
        url: '/search/hotWordsWithAmount',
        method: 'get',
        params: query,
    });
}

// 获取用户最近下载列表
export function ApiRecentlyDownloaded(query) {
    return request({
        url: '/userConsumeRecord/recentlyDownloaded',
        method: 'get',
        params: query,
    });
}

// 获取用户最近查看列表
export function ApiRecentlyViewed(query) {
    return request({
        url: '/userConsumeRecord/recentlyViewed',
        method: 'get',
        params: query,
    });
}
