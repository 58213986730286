import axios from 'axios';
import { MessageBox } from 'element-ui';

import store from '/src/store';
import { responseError } from './response';

// 跨域允许携带cookie
axios.defaults.withCredentials = true;

// 创建axios实例
const service = axios.create({
    baseURL: import.meta.env.VITE_BASE_API, // api的base_url，开发环境 /.env 生成环境 /.env.production
    timeout: 300000, // 请求超时时间 300s
    responseType: 'arraybuffer',
    // headers: { 'content-type': 'application/json' }, // 设置请求头，兼容性更好
});

// request拦截器
service.interceptors.request.use(
    config => {
        // 让每个请求携带自定义token 请根据实际情况自行修改
        config.headers['token'] = store.getters.token;

        return config;
    },
    error => {
        // Do something with request error
        console.log(error); // for debug
        Promise.reject(error);
    }
);

// respone拦截器
service.interceptors.response.use(
    response => {
        const { config, data } = response;

        if (data) {
            try {
                // 文件类型写死为xlsx
                const tmpBlob = new Blob([data], {
                    type: 'charset=utf-8',
                });
                const tmpUrl = window.URL.createObjectURL(tmpBlob);
                // const tmpLink = document.createElement('a');

                // tmpLink.setAttribute('download', 'test.png');
                // tmpLink.style.display = 'none';
                // tmpLink.href = tmpUrl;

                // document.body.appendChild(tmpLink);

                // tmpLink.click();
                // tmpLink.remove();

                return Promise.resolve(tmpUrl);
            } catch (error) {
                MessageBox({
                    title: '文件下载失败',
                    message:
                        '请联系我们的客服解决，错误信息：' +
                        JSON.stringify(error),
                    type: 'error',
                })
                    .then(() => {})
                    .catch(() => {});

                return Promise.reject();
            }
        } else {
            MessageBox({
                title: '文件下载失败',
                message:
                    '请重新尝试下载，或者联系我们的客服解决，错误信息：' +
                    response.statusText,
                type: 'error',
            })
                .then(() => {})
                .catch(() => {});
        }

        return Promise.reject();
    },
    error => {
        return responseError(error);
    }
);

export default service;
