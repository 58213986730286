const getters = {
    token: state => state.user.token,
    vipFlag: state => state.user.vipFlag,
    accessQuery: state => state.user.accessQuery,
    userInfo: state => state.user.userInfo,
    stillCount: state => state.user.stillCount,
    staticData: state => state.user.staticData,
};

export default getters;
