import axios from 'axios';
import store from '/src/store';
import { removeAllSpaces } from '/src/utils/index';
import { responseSuccess, responseError } from './response';

// 跨域允许携带cookie
axios.defaults.withCredentials = true;

// 创建axios实例
const service = axios.create({
    baseURL: import.meta.env.VITE_BASE_API, // api的base_url，开发环境 /.env 生成环境 /.env.production
    timeout: 120000, // 请求超时时间
    headers: { 'content-type': 'application/json' }, // 设置请求头，兼容性更好
});

// request拦截器
service.interceptors.request.use(
    config => {
        // 让每个请求携带自定义token 请根据实际情况自行修改
        config.headers['token'] = store.getters.token;

        // 清除请求参数两端空格
        if (config.data !== null && typeof config.data === 'object') {
            // console.log(typeof(config.data));
            config.data = removeAllSpaces(config.data);
        }

        // 清除请求参数两端空格
        if (config.params !== null && typeof config.params === 'object') {
            config.params = removeAllSpaces(config.params);
        }

        return config;
    },
    error => {
        // Do something with request error
        console.log(error); // for debug
        Promise.reject(error);
    }
);

// respone拦截器
service.interceptors.response.use(
    response => {
        return responseSuccess(response);
    },
    error => {
        return responseError(error);
    }
);

export default service;
