<template>
    <el-container class="layout-container">
        <el-header class="layout-header" height="60px">
            <HeaderBar />
        </el-header>
        <el-main class="layout-main">
            <AppMain />

            <div class="layout-footer">
                <FooterBar />
            </div>
        </el-main>
    </el-container>
</template>

<script>
import { HeaderBar, FooterBar, AppMain } from './components';

export default {
    name: 'Layout',
    components: {
        HeaderBar,
        FooterBar,
        AppMain,
    },
    // computed: {
    //     sidebarOpened() {
    //         const tmpAppSidebar = this.$store.state.app.sidebar;

    //         return tmpAppSidebar.opened;
    //     },
    //     asideWidth() {
    //         const tmpAppSidebar = this.$store.state.app.sidebar;

    //         return tmpAppSidebar.opened ? '200px' : '50px';
    //     },
    // },
};
</script>
