import Layout from '/src/layout/Layout.vue';

const userOrder = () => import('/src/views/user/order.vue');
const userExport = () => import('/src/views/user/export.vue');
const userSetting = () => import('/src/views/user/setting.vue');
const userApi = () => import('/src/views/user/api.vue');
const userCertification = () => import('/src/views/user/certification.vue');
const userRecharge = () => import('/src/views/user/recharge.vue');

const userRouter = {
    path: '/user',
    component: Layout,
    redirect: '/user/setting',
    name: 'user',
    meta: { title: '个人中心' },
    children: [
        {
            path: 'setting',
            component: userSetting,
            name: 'userSetting',
            meta: { title: '个人中心', login: true, navCurrent: 'user' },
        },
        {
            path: 'order',
            component: userOrder,
            name: 'userOrder',
            meta: { title: '我的订单', login: true, navCurrent: 'user' },
        },
        {
            path: 'export',
            component: userExport,
            name: 'userExport',
            meta: { title: '最近下载', login: true, navCurrent: 'user' },
        },
        {
            path: 'view',
            component: userExport,
            name: 'userView',
            meta: { title: '最近查看', login: true, navCurrent: 'user' },
        },
        {
            path: 'api',
            component: userApi,
            name: 'userApi',
            menuHidden: true,
            meta: { title: 'API管理', login: true, navCurrent: 'user' },
        },
        {
            path: 'certification',
            component: userCertification,
            name: 'userCertification',
            menuHidden: true,
            // 访问【企业认证】页面时，设置menuName的值为userApi，左侧菜单【API管理】高亮
            meta: {
                title: '企业认证',
                login: true,
                navCurrent: 'user',
                menuName: 'userApi',
            },
        },
        {
            path: 'recharge',
            component: userRecharge,
            name: 'userRecharge',
            menuHidden: true,
            meta: {
                title: '新增充值记录',
                login: true,
                navCurrent: 'user',
                menuName: 'userApi',
            },
        },
    ],
};

export default userRouter;
