<template>
    <div class="header">
        <div class="wrap">
            <h1 class="logo">
                <a href="/" title="锐眼视界报告网">
                    <img src="/images/logo.svg" alt="锐眼视界报告网" />
                </a>
            </h1>

            <div class="operation">
                <div class="nav">
                    <a
                        v-track:click
                        href="/"
                        class="nav-item"
                        :class="{ current: navCurrent === 'index' }"
                        track-params="t:click_navigationbar__homepage"
                        >首页</a
                    >

                    <a
                        v-track:click
                        href="/membership/index"
                        class="nav-item"
                        :class="{ current: navCurrent === 'membership' }"
                        track-params="t:click_navigationbar__vippage"
                    >
                        <i class="el-icon-vip2 el-icon--left"></i>
                        <span>VIP服务</span>
                    </a>

                    <template v-if="userInfo.weiXinUnionId">
                        <el-popover
                            v-model="navItemShow"
                            placement="bottom-end"
                            :width="vipFlag ? 220 : 260"
                            trigger="hover"
                            class="nav-item"
                            popper-class="nav-item-popper"
                        >
                            <div class="header-popover">
                                <div class="header-popover-top">
                                    <div class="popover-top-avatar">
                                        <el-avatar
                                            :size="42"
                                            :src="userInfo.weiXinHeadImgUrl"
                                        ></el-avatar>
                                    </div>

                                    <div class="popover-top-content">
                                        <div class="user-name text-ellipsis">
                                            {{ userInfo.nickName }}
                                        </div>
                                        <!-- <div class="user-id text-ellipsis">
                                            ID:{{ userInfo.guid }}
                                        </div> -->
                                    </div>

                                    <div
                                        v-if="!vipFlag"
                                        class="popover-top-btn"
                                    >
                                        <el-button @click="handleToVip"
                                            >开通VIP</el-button
                                        >
                                    </div>
                                </div>

                                <div class="header-popover-body">
                                    <el-button
                                        v-track:click
                                        type="text"
                                        track-params="t:hover_navigationbar__personalcenter"
                                        @click="handleAccountCommand('user')"
                                    >
                                        <i class="el-icon-user"></i>
                                        <span class="text">个人中心</span>
                                    </el-button>
                                    <el-button
                                        v-track:click
                                        type="text"
                                        track-params="t:click_navigationbar__logout"
                                        @click="handleAccountCommand('exit')"
                                    >
                                        <i class="rp-icons-logout"></i>
                                        <span class="text">退出登录</span>
                                    </el-button>
                                </div>
                            </div>

                            <div slot="reference" class="nav-item-user">
                                <div class="user-avatar">
                                    <el-avatar
                                        :size="30"
                                        :src="userInfo.weiXinHeadImgUrl"
                                    ></el-avatar>

                                    <i v-if="vipFlag" class="vip-icons"></i>
                                </div>

                                <span>{{ userInfo.nickName }}</span>

                                <i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </div>
                        </el-popover>
                    </template>
                    <template v-else>
                        <div
                            v-track:click
                            class="nav-item"
                            track-params="t:click_navigationbar_login"
                            @click="handleToLogin"
                        >
                            登录
                        </div>
                        <div
                            v-track:click
                            class="nav-item"
                            track-params="t:click_navigationbar_login"
                            @click="handleToLogin"
                        >
                            注册
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <Login ref="Login" @success="handleLoginSuccess" />
        <BuyVip ref="BuyVip" @confirm="handlePayConfirm" />
        <OrderPay ref="OrderPay" @success="handlePaySuccess" />
    </div>
</template>

<script>
import commonMixins from '/src/mixins/commonMixins';

export default {
    name: 'HeaderBar',
    mixins: [commonMixins],
    data() {
        return {
            navItemShow: false,
        };
    },
    computed: {
        routeName() {
            return this.$route.name || '';
        },
        navCurrent() {
            return this.$route.meta?.navCurrent || '';
        },
    },
    // mounted() {
    //     console.log(this.$route.meta.navCurrent);
    // },
    methods: {
        handleAccountCommand(command) {
            // console.log(command);
            switch (command) {
                case 'user':
                    this.$router.push('/user');
                    break;
                case 'exit':
                    this.$confirm('是否确认退出登录?', '退出登录', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    })
                        .then(() => {
                            this.$store.dispatch('FedLogOut');

                            this.$router.push('/');
                        })
                        .catch(() => {});
                    break;
                default:
                    break;
            }

            this.navItemShow = false;
        },

        // 登录
        handleToLogin() {
            this.$refs.Login.handleOpen();
        },

        // 升级vip
        handleToVip() {
            // const { userInfo, vipFlag } = this;

            // if (!userInfo.weiXinUnionId) {
            //     // 未登录
            //     this.$refs.Login.handleOpen();
            // } else if (!vipFlag) {
            //     // 开通VIP
            //     this.$refs.BuyVip.handleOpen();
            // }

            // this.$router.push('/membership/index');

            // 延迟一点跳转，有数据上报操作
            setTimeout(() => {
                window.location.href = '/membership/index';
            }, 300);
        },
    },
};
</script>

<style lang="scss" scoped></style>
